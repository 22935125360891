@font-face {
    font-family: mensajito_font;
    src: url(./fonts/CircularStd-Medium.otf);
}

html,
body,
.container-fluid {
    height: 100%;
}

body {
    background-color: #ffffff;
    font-family: mensajito_font;
}

h1 {
    font-size: 2.2em;
    font-weight: bold;
}

h2 {
    font-size: 1.8em;
    color: #000000;
}

h3 {
    font-size: 22px;
    margin: 9px 0px 9px 0px;
}

label {
    font-size: 22px;
    color: #000000;
    display: block;
}

input {
    font-size: 22px;
    color: #000000;
    font-family: mensajito_font;
}

input[type="file"] {
    padding: 1px 2px;
    margin: 0px;
}

h5 {
    font-family: mensajito_font;
    font-size: 12px;
    color: #9cb898;
}

a:hover {
    text-decoration: none;
}

a,
a:hover,
a:visited {
    color: #9cb89c;
}

.contenido {
    padding: 18px;
}

#container_1 {
    padding: 0px;
    margin: 0 auto;
    width: 80%;
    max-width: 1200px;
}

#navbar_1,
.h1_nav,
.h5_footer {
    padding: 9px;
}

#btn_collapse {
    padding: 9px 9px 9px 9px;
}

.col_nav {
    color: #000000 !important;
}

.col_nav_sel {
    color: #ff0000 !important;
}

#crearBoton {
    font-size: 18px;
    color: #ffffff;
    background-color: red;
}

.div_texto {
    padding: 9px;
}

.texto {
    font-family: "Noto Sans", sans-serif;
    font-size: 24px;
    padding: 9px;
    margin: 0px;
    text-align: justify;
    color: #9cb89c;
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28156, 184, 156, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.p5_div,
.sketch_div {
    padding: 9px;
}

.pro_div {
    padding: 18px;
}

.sketch_pro {
    padding: 9px 18px 9px 18px;
}

.gif_pro {
    padding: 9px 18px 9px 18px;
}

.proy_carousel {
    padding-left: 36px;
    padding-right: 36px;
    padding-top: 18px;
    padding-bottom: 18px;
}

.BotonesStream {
    margin: 0px 18px 0px 18px;
}

.BotonParar {
    background-color: red;
    border-color: red;
}

.BotonParar:hover {
    background-color: #bb0000;
    border-color: #bb0000;
}

#formNombre {
    width: 600px;
}

.eliminar,
.enVivo {
    color: red;
}

.tablaEliminar {
    width: 1000px;
}

#header_principal {
    display: flex;
    padding: 10px;
    justify-content: space-around;
    align-items: center;
}

.contenidoAdmin {
    margin: 30px 150px;
    justify-content: space-around;
    padding: 10px;
}

.stop-audio-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid;
    margin-bottom: 20px;
    padding: 6px 0;
}

.playlist-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 9px;
}

.playlist-element {
    display: block;
    border-bottom: 1px solid;
    margin-bottom: 6px;
    padding: 6px 0;
}

.playlist-element h3 {
    margin: 0;
}

.CrearPlayList {
    display: flex;
    flex-direction: row-reverse;
}

button,
[type="button"],
[type="reset"],
[type="submit"],
[type="file"] {
    font-family: mensajito_font;
    -webkit-appearance: none;
    margin-left: 4px;
    border: none;
    padding: 12px;
    border-radius: 8px;
    font-size: 22px;
    cursor: pointer;
}

.playlist-row {
    margin-bottom: 12px;
}

.backToHome {
    text-align: right;
}

.file-input::-webkit-file-upload-button {
    visibility: hidden !important;
}

.file-input::before {
    content: "Seleccionar archivos";
    display: inline-block;
    background: #efefef;
    border: none;
    border-radius: 8px;
    padding: 12px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 12pt;
}

.file-input:hover::before {
    border-color: black;
}

.file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

input#file-upload {
    width: 200px;
}

.contenedor {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.subtitulo {
    margin: 9px 0px;
}

.buttonBlock {
    margin: 6px;
}

.btn-A {
    color: #FFF;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-B {
    color: #FFF;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-C {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-D {
    color: #FFF;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-R {
    color: #FFF;
    background-color: #dc3545;
    border-color: #dc3545;
}